import React from 'react';
import Home from './Home/Home';

function App() {
  return (
    <Home></Home>
  );
}

export default App;
